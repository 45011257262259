import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useCustomerList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refCustomersTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'id',
      sortable: true,
    },
    {
      key: 'name',
      label: 'customer',
      sortable: true,
    },
    {
      key: 'perContact',
      label: 'contact',
      sortable: true,
    },
    {
      key: 'numberOfFiles',
      label: 'number of files',
      sortable: true,
    },
    {
      key: 'source',
      label: 'source',
      sortable: true,
    },
    {
      key: 'emailVerified',
      label: 'Nirvana SG user',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created By',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalCustomer = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const nric = ref(route.query.nric ?? '')
  const contact = ref(route.query.contact ?? '')
  const email = ref(route.query.email ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')

  const customerSourceFilter = ref([])
  if (Array.isArray(route.query.source)) {
    customerSourceFilter.value = route.query.source
  } else if (route.query.source) {
    customerSourceFilter.value = [route.query.source]
  }
  const customerTypeFilter = ref([])
  if (Array.isArray(route.query.customerType)) {
    customerTypeFilter.value = route.query.customerType
  } else if (route.query.customerType) {
    customerTypeFilter.value = [route.query.customerType]
  }
  const customerData = ref([])
  const createdAtDateFilter = ref(route.query.createdAtDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refCustomersTable.value ? refCustomersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomer.value,
    }
  })

  const refetchData = () => {
    refCustomersTable.value.refresh()
  }

  let timer = null
  watch([searchQuery, contact, nric, email, customerSourceFilter, customerTypeFilter, createdAtDateFilter], () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      currentPage.value = 1
      refetchData()
    }, 1000)
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch('app-all-customers/fetchCustomers', {
        search: searchQuery.value,
        contact: contact.value,
        nric: nric.value,
        email: email.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        customerSource: customerSourceFilter.value,
        customerType: customerTypeFilter.value,
        createdAtDate: createdAtDateFilter.value,
      },
      { root: true })
      .then(response => {
        const { customers, totalCustomers } = response.data
        customerData.value = customers
        callback(customers)
        totalCustomer.value = totalCustomers
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              contact: contact.value,
              nric: nric.value,
              email: email.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              customerSource: customerSourceFilter.value,
              customerType: customerTypeFilter.value,
              createdAtDate: createdAtDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomer,
    dataMeta,
    perPageOptions,
    searchQuery,
    contact,
    nric,
    email,
    sortBy,
    isSortDirDesc,
    refCustomersTable,
    refetchData,
    customerData,

    // Extra Filters
    customerSourceFilter,
    customerTypeFilter,
    createdAtDateFilter,
  }
}
